import React, { PropTypes, useState, useEffect } from "react";
import Collapse from "components/general/Collapse";
import Form from "components/general/FormControllerList";
import _ from "lodash";

const ProductCategory = (props) => {
  let { products_catalogs, categories, catalogs, data } = props;

  let [lastSelectedCatalog, setLastSelectedCatalog] = useState();
  let [addedProductCatalog, setAddedProductCatalog] = useState();

  console.log({
    categories,
    products_catalogs,
    catalogs,
    selectedcatalogs: data.catalogs,
  });
  console.log(_.map(categories, (category) => category._id));

  useEffect(() => {
    if (_.isEmpty(data.catalogs)) products_catalogs = [];
    if (_.isEmpty(products_catalogs)) return;

    let firstProductCatalog = _.head(products_catalogs);
    let { business_id, product_id } = firstProductCatalog;

    let catalogObj = _.find(
      catalogs,
      (log) => log._id === _.last(data.catalogs)
    );
    let productCatalog = _.find(
      products_catalogs,
      (productCatalog) =>
        productCatalog.catalog_id? productCatalog.catalog_id._id: null === _.last(data.catalogs)
    );
    console.log("useEffect product catalog", productCatalog);
    if (_.isEmpty(productCatalog))
      products_catalogs.push({
        business_id,
        product_id,
        catalog_id: { ...catalogObj },
        categories: getCategoriesIds(categories, catalogObj),
      });
  }, [data.catalogs]);

  console.log(products_catalogs);
  console.log(data.catalogs);
  console.log(catalogs);

  return (
    <Collapse group={{ label: "Category", id: 32, open: false }}>
      {catalogs.map(
        (catalog, index) =>
          _.includes(data.catalogs, catalog._id) && (
            <div className="p-l-25 p-r-25">
              <Form
                {...props}
                type={"groupCheckbox"}
                label={catalog.name_en}
                name={"categories"}
                options={getCategoyOptions(categories, catalog)}
                value={getCategory(products_catalogs, catalog)}
                default={getCategory(products_catalogs, catalog)}
                target={_.find(
                  products_catalogs,
                  (productCatalog) =>
                    productCatalog.catalog_id._id === catalog._id
                )}
                targetName="products_catalogs"
                valueKey={"_id"}
                labelKey={"name_en"}
                disabled={true}
                updateState={props.onChange}
              />
            </div>
          )
      )}
    </Collapse>
  );
};

ProductCategory.propTypes = {};

export default ProductCategory;

function getCategory(products_catalogs, catalog) {
  console.log({ products_catalogs, catalog });

  return _.find(
    products_catalogs,
    (productCatalog) => productCatalog.catalog_id._id === catalog._id
  )
    ? _.find(
        products_catalogs,
        (productCatalog) => productCatalog.catalog_id._id === catalog._id
      ).categories
    : [];
}

function getCategoyOptions(categories, catalog) {
  let list = [];
  console.log({ categories, catalog });
  _.map(categories, (cat) => {
    _.map(cat.catalogs, (log) => {
      if (log._id === catalog._id) list.push(cat);
    });
  });
  return list;
}

function getCategoriesIds(categories, catalog) {
  let list = [];
  _.map(categories, (cat) => {
    _.map(cat.catalogs, (log) => {
      if (log._id === _.get(catalog, '_id')) list.push(cat._id);
    });
  });

  return list;
}
