import React, { useState } from "react";
import { Col, Button, Form, Row } from "reactstrap";

import Card from "components/general/Card";
import moment from "moment";
// import DeleteAttribute from "../GroupsList/DeleteAttribute";

import FormComponent from "components/general/FormController";
import Options from "components/general/Attribute/Options";

import { Alert } from "reactstrap";
import RightSideDetails from "components/general/Attribute/RightSideDetails";

const View = (props) => {
  let { onChange, data, attributeTypes, onSubmit, errors, onValidate,handleDownloadExcel } = props;

  const [deleteModal, setDeleteModal] = useState(false);

  const toggleDeleteAttribute = () => {
    setDeleteModal(!deleteModal);
  };
  return (
    <Card
      goBack={props.history ? props.history.goBack : {}}
      name="Go Back To Product Attributes"
      titleStyle="cursor-pointer"
    >
      <Form onSubmit={onSubmit}>
        <Row className="form-row">
          <Col md={8}>
            <div className="modal-body">
              <FormComponent
                type="text"
                name="name_en"
                label="English Name"
                value={data.name_en}
                updateState={onChange}
                slug={false}
              />
              <FormComponent
                type="text"
                name="name_ar"
                label="Arabic Name"
                value={data.name_ar}
                updateState={onChange}
                slug={false}
              />

              <FormComponent
                type="text"
                name="inner_name"
                label="Internal Name"
                value={data.inner_name}
                updateState={onChange}
                disabled={true}
              />

              <FormComponent
                type="textarea"
                name="description_en"
                label="English Description"
                value={data.description_en}
                updateState={onChange}
              />
              <FormComponent
                type="textarea"
                name="description_ar"
                label="Arabic Description"
                value={data.description_ar}
                updateState={onChange}
              />

              {props.groupsOfAttributes ? (
                <FormComponent
                  type="select"
                  options={props.groupsOfAttributes}
                  name="group_id"
                  label="Group Atributes"
                  value={data.group_id}
                  valueKey="_id"
                  labelKey="label"
                  updateState={onChange}
                  isDisabled={data.class === null ? true : false}
                />
              ) : (
                "Loading"
              )}

              <FormComponent
                type="select"
                options={attributeTypes}
                name="type"
                targetLabel="typeLabel"
                label="Type"
                value={data.type}
                valueKey="value"
                labelKey="label"
                optional="typeCase"
                optionalKey="case"
                updateState={onChange}
                isDisabled={data.class === null ? true : false}
              />

              {/*checkbox Group options case 2 */}

              {(data.typeCase === 2 || data.typeCase === 3) && (
                <Options
                  data = {data}
                  updateState={onChange}
                  typeCase={data.typeCase}
                  options={data.option}
                  hasAddOption={true}
                  handleDownloadExcel={handleDownloadExcel}
                />
              )}

              {/* Single checkbox Option case 1 */}
              {data.typeCase === 1 && (
                <Options
                  data = {data}
                  onAddOption={props.onAddOption}
                  typeCase={data.typeCase}
                  options={data.option}
                />
              )}

              <FormComponent
                type="singleCheckbox"
                name="to_search"
                label="Make it searchable"
                value={data.to_search}
                defaultChecked={data.to_search}
                updateState={onChange}
              />
               <FormComponent
                type="singleCheckbox"
                name="is_filtered"
                label="Make filterable"
                value={data.is_filtered}
                defaultChecked={data.is_filtered}
                updateState={onChange}
              />
              <FormComponent
                type="singleCheckbox"
                name="is_configurable"
                label="Make it configurable"
                value={data.is_configurable}
                defaultChecked={data.is_configurable}
                updateState={onChange}
              />
              <FormComponent
                type="singleCheckbox"
                name="is_required"
                label="Make it required"
                value={data.is_required}
                defaultChecked={data.is_required}
                updateState={onChange}
              />
              <FormComponent
                type="singleCheckbox"
                name="in_form"
                label="in form"
                value={data.in_form}
                defaultChecked={data.in_form}
                updateState={onChange}
              />


              {errors && <Alert color="danger"> {errors.required} </Alert>}
            </div>
          </Col>
          <Col md={4}>
            <RightSideDetails
              onSubmit={onSubmit}
              title={"product"}
              deleteAction={props.deleteAction}
              item={{ name: data.name_en, id: props.id }}
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

View.propTypes = {};

export default View;
